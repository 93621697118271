import { Box, Typography } from '@secfi/components';
import { palette, sizes } from '@secfi/styles';
import React, { FC } from 'react';

import { adminBar } from '../../types/wording';

interface Props {
  isMirroring: boolean;
  message?: string;
}

export const AdminNotificationBar: FC<Props> = ({
  isMirroring,
  message: messageProp,
}) => {
  const message = messageProp
    ? messageProp
    : isMirroring
    ? adminBar.mirroring
    : adminBar.admin;

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: palette.green[900],
        display: 'flex',
        height: sizes.sz48,
        justifyContent: 'center',
        textAlign: 'center',
        top: 0,
        width: '100%',
        zIndex: ({ zIndex }) => zIndex.appBar,
        position: 'relative',
      }}
    >
      <Typography variant="m" color="white">
        {message}
      </Typography>
    </Box>
  );
};
