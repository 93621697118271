import React, { useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { AppPaths } from 'src/constants/routes';
import { analytics } from 'src/services/analytics/analyticsService';

export const LoginCallbackRoute = () => {
  const { provider } = useParams<{ provider: string }>();
  const location = useLocation();

  const search = location.search && `${location.search}&provider=${provider}`;
  useEffect(() => {
    analytics.page('Login - Callback', {});
  }, []);

  return (
    <Navigate
      to={{
        pathname: AppPaths.Login,
        search,
      }}
    />
  );
};
