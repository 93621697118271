import { CurrencyModel } from '@generated';
import { flatten, unflatten } from 'flat';
import endsWith from 'lodash/endsWith';
import fromPairs from 'lodash/fromPairs';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import omit from 'lodash/omit';
import toPairs from 'lodash/toPairs';

// if only `__typename` has to be removed, provide `fields` value as an empty array
export const stripFields = <
  T extends Record<string, any>,
  K extends keyof T = never
>(
  object: T,
  omitableFields: K[] = []
): Omit<T, K> => {
  let result = omit(object, omitableFields);

  const flattenedObject = flatten<Omit<T, K>, Record<string, unknown>>(result);
  result = unflatten(
    fromPairs(
      toPairs(flattenedObject).filter(([key]) => !endsWith(key, '__typename'))
    )
  );

  return result;
};

/**
 * Deeply applies `formatter` to each values in the `source` object
 * @param source the source object to format
 * @param formatter the formatter function
 * @returns returns an new object of the sme shape on which the formatter have been applied to every values
 */
export const deepFormatValues = <
  T extends Record<string, any> | any,
  U extends T = T
>(
  source: T,
  formatter: (value: any, key: string, source?: T) => any
): U =>
  source &&
  // 4: `unflatten` reshape the object in depth `{"foo.far": "BAR"} => {foo: {far: "BAR"}}`
  unflatten(
    // 3: `fromPairs` transforms an array of shape `["foo.far", "BAR"]` to an object `{"foo.far": "BAR"}`
    fromPairs(
      map(
        flatten<T, any>(source), // 1: {foo: {far: "bar"}} => {"foo.far": "bar"}
        (value: any, key: string) => [key, formatter(value, key, source)] // 2: {"foo.far": "bar"} => ["foo.far", formatter("bar") = "BAR"]
      )
    )
  );

export const createCurrencyModel = (
  value: number | string | null | undefined
): CurrencyModel | null => {
  if (
    typeof value === 'undefined' ||
    value === null ||
    (typeof value === 'number' && isNaN(value))
  ) {
    return null;
  }

  return {
    value: `${value}`,
    currency: 'USD',
  };
};

export const createNonNullableCurrencyModel = (
  value: number | string
): CurrencyModel => ({
  value: `${value}`,
  currency: 'USD',
});

/**
 * Function that takes an object, and clears out all its properties that are `null` or `undefined`
 * @param obj object to clear
 * @returns new instance of object without its nil properties
 */
export const clearNilProperties = <T extends Record<string, any>>(
  obj: T
): Partial<T> =>
  Object.fromEntries(
    Object.entries(obj).filter(([, value]) => !isNil(value))
  ) as Partial<T>;

export function typedKeys<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}
